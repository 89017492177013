import "./appointments.scss";
import "../pages.scss";

import moment from 'moment-timezone';
import Button from "../../components/button/button";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import ErrorFinalPage, {MessageType} from "../confirmation/errorFinal";
import SingleConsultantPanel from "../../pages2/consultant/consultant";
import axios from "axios";
import config from "../../config";

function AppointmentsPage({data, deleteOne}) {
    const [apptToCancel, setApptToCancel] = useState(null);
    const [inProgress, setInProgress] = useState(false);

    const [errorVisible, setErrorVisible] = useState(false);
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [finalInformationVisible, setFinalInformationVisible] = useState(false);

    useEffect(() => {
            setApptToCancel(null);
            setInProgress(false);
        },
        // eslint-disable-next-line
        [data.appointments])

    let getAppointments = () => {
        return data.appointments;
    }
    let formatDate = (d) => {
        return moment(d).format("YYYY-MM-DD HH:mm");
    }

    const handleErrorData = () => {
        let dataCopy = _.cloneDeep(data);
        dataCopy._error = {type: "info", message: MessageType.CANCELLED_APPOINTMENT}
        return dataCopy
    }

    const getConfirmDelete = () => {
        if (data.confirmedDelete) {
            return (
                <div className="appointments page marg-bottom">
                    <ErrorFinalPage data={handleErrorData()}/>
                </div>
            )
        }
    }

    let askToCancel = (appt) => {
        return () => {
            // console.log("appt", appt);
            setApptToCancel(appt);
        }
    }

    const deleteAppointmentAPI = (appt) => {
        axios.delete(`${config.api_host}/api/dict/appointment/register?identifier=${appt.identifier}`)
            .then(function (response) {
                // deleteOne(apptToCancel);
                setFinalInformationVisible(true);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                // setPage(99);

                setErrorVisible(true);
            })
            .finally(function () {
                // always executed
            });
    }

    let deleteAppointment = () => {
        // console.log("apptToCancel", apptToCancel);
        deleteAppointmentAPI(apptToCancel);
        // deleteOne(apptToCancel);
        setInProgress(true);
    }

    const cancelAppointment = (item) => {
        setApptToCancel(item);
        setConfirmationVisible(true);
    }

    let showErrorModal = retryButtonPresent => {
        setErrorVisible(true);
    }

    if (!getAppointments()) {
        return (
            <>
                <div className="appointments page">
                    <div className={"text-in"}>
                        Loading all appointments ... please wait ...
                    </div>
                </div>
            </>
        )
    }

    if (getAppointments() && getAppointments().length === 0) {
        return (
            <>
                <div className="appointments page">
                    <div className={"text-in"}>
                        You have no upcoming appointments
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="appointments page">
                {data.appointments &&
                    <div className={"appointments-list"}>
                        {getAppointments().map(appointment => (
                            <div key={appointment.identifier}>
                                <SingleConsultantPanel itemSource={appointment.consultant}
                                                       appointment={appointment}
                                                       noPadding={true}
                                                       selectable={false}
                                                       cancelable={true}
                                                       appointmentSummary={true}
                                                       placeholder={false}
                                                       cancelEvent={cancelAppointment}
                                                       consultantsDetails={data.consultantsDetails}/>
                            </div>
                        ))}
                    </div>
                }
            </div>

            {/* Message box ... done */}
            <div className={"app-modal-message"} style={{
                display: finalInformationVisible ? "flex" : "none"
            }}>
                <div className={"message-stretch"}></div>
                <div className={"message"}>
                    <div className={"message-header"}>
                        <div className={"message-header-label info"}>
                            Done
                        </div>
                        <div className={"message-header-close"}>
                            <div onClick={() => {
                                setFinalInformationVisible(false);
                                deleteOne(apptToCancel);
                            }}>
                                <img src={"/close2.png"} />
                            </div>
                        </div>
                    </div>
                    <div className={"message-body"}>
                        Your appointment has been cancelled.
                    </div>
                </div>
                <div className={"message-stretch"}></div>
            </div>

            {/* Message box ... confirm or cancel appointment removal */}
            <div className={"app-modal-message"} style={{
                display: errorVisible ? "flex" : "none"
            }}>
                <div className={"message-stretch"}></div>
                <div className={"message"}>
                    <div className={"message-header"}>
                        <div className={"message-header-label error"}>
                            Error
                        </div>
                        <div className={"message-header-close"}>
                            <div onClick={() => {
                                setErrorVisible(false);
                            }}>
                                <img src={"/close2.png"} />
                            </div>

                        </div>
                    </div>
                    <div className={"message-body"}>
                        Your appointment cant be cancelled at this time. Please try again later. <br/>
                        <br/>
                        Alternatively you can contact our bookings team on <a className={"phone-call"} href="tel:080045733746"><b>0800 4573 3746</b></a>, they may be able to
                        cancel your MyWay appointment for you.
                    </div>
                </div>
                <div className={"message-stretch"}></div>
            </div>

            {/* Message box ... confirm or cancel appointment removal */}
            <div className={"app-modal-message"} style={{
                display: confirmationVisible ? "flex" : "none"
            }}>
                <div className={"message-stretch"}></div>
                <div className={"message"}>
                    <div className={"message-header"}>
                        <div className={"message-header-label info"}>
                            Please confirm
                        </div>
                        <div className={"message-header-close"}>
                            <div onClick={() => {
                                setConfirmationVisible(false);
                            }}>
                                <img src={"/close2.png"} />
                            </div>
                        </div>
                    </div>
                    <div className={"message-body"}>
                        Your appointment will be cancelled.<br/>
                        <br/>
                        Are you sure you want to cancel? This action cannot be undone.
                    </div>

                    <div className={"message-buttons"}>
                        <div className={"message-button"} onClick={() => {
                            setConfirmationVisible(false);
                        }}>
                            Back
                        </div>
                        <div className={"message-button important"} onClick={() => {
                            setConfirmationVisible(false);
                            deleteAppointment();
                        }}>
                            Cancel appointment
                        </div>
                    </div>
                </div>
                <div className={"message-stretch"}></div>
            </div>
        </>
    )
}

export default AppointmentsPage;
